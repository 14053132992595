// import React, { useState, useEffect, useRef } from 'react';
// import * as tf from '@tensorflow/tfjs';
// import Webcam from 'react-webcam';

// const labels = [
//   '5', 'G', 'Good', 'He or She', 'I am', 'Introduction', 'Name', 'What', 'Who', 'You'
// ];

// const HandGestureRecognition = () => {
//   const [model, setModel] = useState(null);
//   const [prediction, setPrediction] = useState('');
//   const webcamRef = useRef(null);
//   const requestRef = useRef(null);

//   useEffect(() => {
//     const loadModel = async () => {
//       try {
//         const customModel = await tf.loadLayersModel('http://localhost:3010/model/model.json');
//         setModel(customModel);
//         console.log('Model loaded successfully');
//       } catch (error) {
//         console.error('Error loading model:', error);
//       }
//     };

//     loadModel();
//   }, []);

//   const processFrame = async () => {
//     if (webcamRef.current && webcamRef.current.video.readyState === 4) {
//       const video = webcamRef.current.video;
//       const tensor = tf.browser.fromPixels(video)
//         .resizeNearestNeighbor([128, 128])
//         .toFloat()
//         .div(tf.scalar(255.0))
//         .expandDims();

//       const predictions = await model.predict(tensor).data();
//       const maxIndex = predictions.indexOf(Math.max(...predictions));
//       setPrediction(labels[maxIndex]);

//       tf.dispose(tensor); // Clean up tensor to prevent memory leaks
//     }
//     requestRef.current = requestAnimationFrame(processFrame);
//   };

//   useEffect(() => {
//     if (model) {
//       requestRef.current = requestAnimationFrame(processFrame);
//       return () => cancelAnimationFrame(requestRef.current); // Cleanup on unmount
//     }
//   }, [model]);

//   return (
//     <div>
//       <Webcam
//         audio={false}
//         ref={webcamRef}
//         screenshotFormat="image/jpeg"
//         width={640}
//         height={480}
//       />
//       {prediction && <div>Prediction: {prediction}</div>}
//     </div>
//   );
// };

// export default HandGestureRecognition;

// export default HandGestureRecognition;
import React, { useState, useEffect, useRef } from 'react';
import * as tf from '@tensorflow/tfjs';
import Webcam from 'react-webcam';
import './HandGestureRecognition.css';

// const labels = [
//   '5', 'G', 'Good', 'He or She', 'I am', 'Introduction', 'Name', 'What', 'Who', 'You'
// ];
// const labels = ['1', '2', '3', '4', '5', '6', '7', '9', '10', 'Aku', 'Apa', 'Apakabar', 'Baik', 'Dia', 'Kamu', 'Nama', 'Perkenalan', 'Siapa'];
const labels = [
  '10', 
  '1_1', 
  '2', 
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'Good',
  'H',
  'He or she',
  'I',
  'I am',
  'Introduction',
  'J',
  'K',
  'L',
  'M',
  'N',
  'Name',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'What',
  'Who',
  'X',
  'Y',
  'You',
  'Z'
];
const HandGestureRecognition = () => {
  const [model, setModel] = useState(null);
  const [prediction, setPrediction] = useState('');
  const [predictionPercentage, setPredictionPercentage] = useState('');
  const [cameraType, setCameraType] = useState('user'); // 'user' for front, 'environment' for back
  const webcamRef = useRef(null);
  const requestRef = useRef(null);

  useEffect(() => {
    const loadModel = async () => {
      try {
        
        // const customModel = await tf.loadLayersModel('http://localhost:3010/model/model.json');
        const customModel = await tf.loadLayersModel("https://www.virtual-tour.psycopedia.net/model_44_10/model.json");
        setModel(customModel);
        console.log('Model loaded successfully');
      } catch (error) {
        console.error('Error loading model:', error);
      }
    };

    loadModel();
  }, []);

  const processFrame = async () => {
    if (webcamRef.current && webcamRef.current.video.readyState === 4 && model) {
      tf.tidy(() => {
        const video = webcamRef.current.video;
        const tensor = tf.browser.fromPixels(video)
          .resizeNearestNeighbor([128, 128])
          .toFloat()
          .div(tf.scalar(127.5))
          .sub(tf.scalar(1.0))
          .expandDims();

        const predictions = model.predict(tensor);
        const predictionData = predictions.dataSync(); // Use dataSync for synchronous read
        const maxIndex = predictionData.indexOf(Math.max(...predictionData));
        const percentage = Math.min((predictionData[maxIndex] * 100).toFixed(2), 100); // Ensure max 100%
        setPrediction(labels[maxIndex]);
        setPredictionPercentage(percentage);

        // Clean up tensors
        tf.dispose(predictions);
      });
    }
    requestRef.current = requestAnimationFrame(processFrame);
  };

  useEffect(() => {
    if (model) {
      requestRef.current = requestAnimationFrame(processFrame);
      return () => {
        cancelAnimationFrame(requestRef.current); // Cleanup on unmount
        tf.disposeVariables();
      };
    }
  }, [model]);

  const handleCameraSwitch = () => {
    setCameraType((prevType) => (prevType === 'user' ? 'environment' : 'user'));
  };

  return (
    <div className="container">
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={640}
        height={480}
        videoConstraints={{ facingMode: cameraType }}
        className="webcam"
      />
      {prediction && (
        <div className="prediction">
          Prediction: {prediction} ({predictionPercentage}%)
        </div>
      )}
      <button onClick={handleCameraSwitch} className="switch-button">
        Switch Camera
      </button>
    </div>
  );
};

export default HandGestureRecognition;

